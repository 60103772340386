import React from 'react';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Overdrive from 'react-overdrive';
import styled from 'react-emotion';

import { Layout, ProjectHeader, ProjectPagination, SEO } from 'components';
import config from '../../config/site';

class CenteredImg extends React.Component {
  constructor(props) {
    super(props);
    this.lastHeight = 400;
    this.lastWidth = 800;
    this.state = {
      windowHeight: this.lastHeight,
      windowWidth: this.lastWidth
    };
  }

  handleResize = () => {
    if (this == undefined || this.setState == undefined) {
      return;
    }
    // Only update state if window height changes more than 33%
    if (window.innerWidth != this.lastWidth ||
        Math.abs(window.innerHeight/this.lastHeight - 1) > 0.33) {
      this.setState({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      }, () => {
        this.lastHeight = this.state.windowHeight;
        this.lastWidth = this.state.windowWidth;
      });
    }
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    const vertical = this.props.fluid.aspectRatio < this.state.windowWidth/this.state.windowHeight;
    return (
      <Img
        key={this.props.fluid.src}
        fluid={this.props.fluid}
        style={ vertical ?
          { width: this.state.windowHeight*0.9*this.props.fluid.aspectRatio } :
          { width: this.state.windowWidth*0.9 }}
      />
    );
  }
}

const OuterWrapper = styled('div')`
  padding: 0 ${props => props.theme.contentPadding};
  margin: -6rem auto 6rem auto;
`;

const InnerWrapper = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-end;

  position: relative;
  max-width: ${props => `${props.theme.maxWidths.project}px`};
  margin: -35px -25px;
`;

const Tile = styled('div')`
  margin: 35px 25px;
  position: relative;
  text-align: center;
`;

function romanize(num) {
  var lookup = {M:1000,CM:900,D:500,CD:400,C:100,XC:90,L:50,XL:40,X:10,IX:9,V:5,IV:4,I:1},roman = '',i;
  for ( i in lookup ) {
    while ( num >= lookup[i] ) {
      roman += i;
      num -= lookup[i];
    }
  }
  return roman;
}

const Project = ({ pageContext: { slug, prev, next }, data: { headShot: { childImageSharp }, project: postNode, images: imgs } }) => {
  const images = imgs.edges;
  const project = postNode.frontmatter;

  return (
    <Layout>
      <Helmet title={`${project.title} | ${config.siteTitle}`} />
      <SEO postPath={slug} postNode={postNode} postSEO />
      <ProjectHeader
        avatar={childImageSharp.fluid}
        name={config.name}
        date={project.date}
        title={project.title}
        areas={project.areas}
        text={postNode.html}
        slug={slug}
      />
      <OuterWrapper>
        <InnerWrapper>
          {images.map(({ node }, i) => {
            return <Tile key={i}>
                <CenteredImg fluid={node.childImageSharp.fluid}/>
                <div>{romanize(i+1)}</div>
              </Tile>;
          })}
        </InnerWrapper>
        <ProjectPagination next={next} prev={prev} />
      </OuterWrapper>
    </Layout>
  );
};

export default Project;

Project.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    next: PropTypes.object,
    prev: PropTypes.object,
  }),
  data: PropTypes.shape({
    project: PropTypes.object.isRequired,
    images: PropTypes.object.isRequired,
    headShot: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }),
    }),
  }).isRequired,
};

Project.defaultProps = {
  pageContext: PropTypes.shape({
    next: null,
    prev: null,
  }),
};

export const pageQuery = graphql`
  query ProjectPostBySlug($slug: String!, $absolutePathRegex: String!) {
    headShot: file(relativePath: { eq: "headShot.png" }) {
      childImageSharp {
        fluid(maxWidth: 250, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    images: allFile(
      filter: { absolutePath: { regex: $absolutePathRegex }, extension: { eq: "jpg" } },
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 4400, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    project: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        cover {
          childImageSharp {
            fluid(maxWidth: 4400, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 800) {
              src
            }
          }
        }
        date(formatString: "DD.MM.YYYY")
        title
        areas
      }
    }
  }
`;
